export const environment = {
  production: true,

  organizationCode: '',
  singleTenantMode: false,
  apiVersion: '4.4.8508',

  publicUrl: 'azuredev.mysecurenvoy.com',

  master: 'https://api-securenvoy-cloudv2-master-dev-uk.azurewebsites.net',
  analytics: 'https://api-securenvoy-cloudv2-analytics-dev-uk.azurewebsites.net',
  user: 'https://api-securenvoy-cloudv2-user-dev-uk.azurewebsites.net',
  activityLogging: 'https://api-securenvoy-cloudv2-activitylogging-dev-uk.azurewebsites.net',
  filestore: 'https://api-securenvoy-cloudv2-filestore-dev-uk.azurewebsites.net',
  notification: 'https://api-securenvoy-cloudv2-notification-dev-uk.azurewebsites.net',
  identity: 'https://api-securenvoy-cloudv2-identity-dev-uk.azurewebsites.net',
  organization: 'https://api-securenvoy-cloudv2-organization-dev-uk.azurewebsites.net',
  conditionalAccess: 'https://api-securenvoy-cloudv2-conditionalaccess-dev-uk.azurewebsites.net',
  migration: 'https://api-securenvoy-cloudv2-migration-dev-uk.azurewebsites.net',
  report: 'https://api-securenvoy-cloudv2-report-dev-uk.azurewebsites.net',

  identityApi: '/identity',
  identityRedirect: '/auth-callback',
  identityChangePassword: '/auth-callback&changePassword=true',
  identityLogout: '/auth/logout',
  identitySilentRedirect: '/assets/silent-renew.html',
  identityInitiatedUrl: 'https://{org_code}./identity/saml2?app=$app_id$&ServiceProvider=$thirdPartyEntityId$&AssertConsumerUrl=$thirdPartySignInUrl$',
  reCaptchaSiteKey: '6LchDL8bAAAAAImIM5xOBZkXvedF8Hh2mt6EIrrD',

  azureLoginUri: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  azureClientId: '8d01827f-f345-4098-be94-c52be2e2bc35',
  azureRedirect: '/cloud/azure/custom-redirection',

  googleDirectoryAllowedOrigin: "https://",
  googleDirectoryClientId: "",
  googleDirectoryClientSecret: "",

  contactUrl: '',

  downloadcookie_expire_mins: 1,
  downloadcookie_domain: 'azuredev.mysecurenvoy.com',

  pushCheckTime: 5, //seconds
  token: ''
};
